import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {createEditor, CustomText, Editor, Node, Point, Range, Text as SlateText, Transforms} from "slate";
import {FaBold, FaItalic, FaUnderline} from "react-icons/fa6";
import {withHistory} from "slate-history";
import {Editable, ReactEditor, Slate, useFocused, useSlate, withReact} from "slate-react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    Flex,
    Icon,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Skeleton,
    SkeletonText,
    Spinner,
    Text,
    Tooltip,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import ReactPlayer from "react-player";
import {ChevronLeftIcon} from "@chakra-ui/icons";

import {RiPriceTag3Line, RiQuestionMark} from "react-icons/ri";
import {useUser} from "../../context/UserContext";
import {useAuth} from "../../context/AuthContext";
import {apiStates, useApi} from "../../api/braincap-api";
import {handleSplitParagraph} from "../editors/slate-helpers";
import {ToolbarButton, ToolbarMenu, ToolbarPortal} from "../editors/toolbar-helper";
import {useWorkspace} from "../../context/WorkspaceContext";
import {useParams} from "react-router-dom";
import {TranscriptionSession} from "../../models/Localization";
import {millisecondsToHHMMSS, secondsToHHMMSS} from "../../utils/firebase";

export const TranscriptPage = () => {
    const user: any = useUser()
    const auth: any = useAuth()
    const workspace: any = useWorkspace()
    const { sessionId, podcastId, episodeId }: any = useParams();

    const editor = useMemo(() => withReact(withHistory(createEditor() as ReactEditor)), []);
    const [transcriptLoading, setTranscriptLoading] = useState<boolean>(true);
    const [transcriptSaving, setTranscriptSaving] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [nodes, setNodes] = useState<Node[]>([]);
    const [task, setTask] = useState<TranscriptionSession>();
    const [value, setValue] = useState<Node[]>([]);
    const [isToolbarVisible, setToolbarVisible] = useState(false);
    const [lastHighlightedWordIndex, setLastHighlightedWordIndex] = useState<number | null>(null);
    const [speakers, setSpeakers] = useState<any[]>([]);
    const [lastHighlightedWordId, setLastHighlightedWordId] = useState(null);
    const [currentTimestamp, setCurrentTimestamp] = useState<number>(0);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef: any = React.useRef()
    const [actions, setActions] = useState<any[]>([]);
    const [filteredActions, setFilteredActions] = useState<any[]>([]);
    const [openActionBox, setOpenActionBox] = useState<boolean>(false);
    const transcriptReq = useApi(`/transcription/session/get/${sessionId}/${podcastId}/${episodeId}`, user.token, false);
    const transcriptSaveReq = useApi(`/transcription/session/save`, user.token, false);
    const transcriptSubmitReq = useApi(`/transcription/session/submit/${sessionId}/${podcastId}/${episodeId}`, user.token, false);
    const [speakersList, setSpeakersList] = useState<any[]>([]);
    const [playSegment, setPlaySegment] = useState<boolean>(false);
    const [playSegmentStart, setPlaySegmentStart] = useState<number>(0);
    const [savingQueue, setSavingQueue] = useState<any[]>([]);
    const [syncWithServer, setSyncWithServer] = useState<boolean>(false);
    const [lastSavedUpdate, setLastSavedUpdate] = useState<any>(null);
    const toast = useToast();
    const transcriptRef: any = useRef(null);
    const initialValue: any = useMemo(
        () =>
            JSON.parse(localStorage.getItem(`${user.userId}-${podcastId}-${episodeId}`) as string),
        []
    )

    useEffect(() => {
        if (user.user && !workspace.jobsLoading) {
            setTranscriptLoading(true)
            transcriptReq.setPayloadData({
                labelerId: user.userId,
            })
        }
    }, [user.loading, workspace.jobsLoading])

    //add a timer to save the transcript every 2 minutes writing to the DB

    useEffect(() => {
        if (!transcriptReq.loading && !!transcriptReq.data) {
            if (transcriptReq.data.last_updated != null) {
                setLastSavedUpdate(
                    {
                        mediaTimestamp: transcriptReq.data.last_updated.mediaTimestamp,
                        time: transcriptReq.data.last_updated.time
                    }
                )
            }
            setTask(transcriptReq.data.session)
            setValue(transcriptReq.data.transcript)
            setActions(transcriptReq.data.actions)
            setTranscriptLoading(false)
        }
    }, [transcriptReq.loading, transcriptReq.data])

    useEffect(() => {
        setFilteredActions(actions)
    }, [actions])

    useEffect(() => {
        if (task) {
            workspace.setNavBarEpisodeTitle(task.episode.title)
            workspace.setOverrideNavBarEpisodeTitle(true)
        }
    }, [task])

    useEffect(() => {
        if (!transcriptSaveReq.loading && transcriptSaveReq.state === apiStates.SUCCESS) {
            toast({
                title: 'Success',
                description: 'Transcript saved successfully',
                status: 'success',
                duration: 500,
                isClosable: true,
                position: 'bottom-right'

            })
            setTranscriptSaving(false)
        }
        if (!transcriptSaveReq.loading && transcriptSaveReq.error) {
            toast({
                title: 'Error',
                description: 'Could not save transcript. Please try again or contact support.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            })
            setTranscriptSaving(false)
        }
    }, [transcriptSaveReq.loading, transcriptSaveReq.data])

    useEffect(() => {
        if (!transcriptSubmitReq.loading && transcriptSubmitReq.state === apiStates.SUCCESS) {
            toast({
                title: 'Transcript Submitted',
                description: 'Transcript submitted successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            deleteLocalTranscript()
            window.location.href = '/dashboard'
        }

        if (!transcriptSubmitReq.loading && transcriptSubmitReq.error) {
            toast({
                title: 'Error',
                description: 'Could not submit transcript. Please try again or contact support.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
        setSubmitting(false)
    }, [transcriptSubmitReq.loading, transcriptSubmitReq.data])

    const handleAudioTimeUpdate = useCallback((currentTime: any) => {
        // currentTime is expected to be in seconds, convert it to milliseconds
        const currentTimeMs = currentTime * 1000;
        const nodes = value; // Assuming 'value' holds your nodes with time-stamped text

        // Find the node that corresponds to the current time
        const targetNode: any = nodes.find((node: any) => {
            const { start, end } = node.children[0]; // Assuming the first child has the timestamp
            return start <= currentTimeMs && currentTimeMs <= end;
        });

        if (transcriptRef.current && targetNode) {
            // Find the DOM element that corresponds to the node
            // For this, you might have to assign an ID or some other identifier to each node's DOM element when rendering the transcript
            const elementId = `transcript-${targetNode.children[0].start}`; // This assumes you give each text node a unique ID based on its start time
            const element = document.getElementById(elementId);

            if (element) {
                // Scroll to the DOM element
                transcriptRef.current.scrollTop = element.offsetTop - transcriptRef.current.offsetTop;
            }
        }
    }, [value]);

    const getSlateContent = () => {
        return value;
    };

    const WordSpans = ({...props}) => {
        return (
            <>
                {props.element.children[0].words.map((word:any, index: any) => {
                    const wordAttributes = {
                        'data-word-start': word.start,
                        'data-word-end': word.end,
                        'data-word-id': word.id,
                    };
                    return (
                        <Box
                            as="span"
                            id={`${word.start}-${word.end}`}
                            key={index}
                            mr={index !== props.element.children[0].words.length - 1 ? 2 : 0} // Add some margin to separate words
                            display="inline-block"
                            {...wordAttributes}
                        >
                            {word.text}
                        </Box>
                    )
                })
                }
            </>
        )
    }

    const renderElement = useCallback(({...props}) => {
        let list = props.element.speakersList
        switch (props.element.type) {
            case 'timedText':
                return (
                    <Flex direction={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} w={'full'} {...props.attributes} bg={props.element.highlight ? 'yellow.100' : 'transparent'}>
                        <Menu>
                            <MenuButton userSelect={'none'}>
                                <Text contentEditable={false} color="teal.600" fontSize="12px" fontWeight="600" lineHeight="16px">
                                    {props.element.speaker}
                                </Text>
                            </MenuButton>
                            <MenuList>
                                {list.map((speaker: any) => {
                                    return (
                                        <MenuItem contentEditable={false} onClick={handleSetSpeaker.bind(this, props, speaker.name, true)}>
                                            {speaker.name}
                                        </MenuItem>
                                    )
                                })}
                            </MenuList>
                        </Menu>
                        <p>
                            {props.children}
                        </p>
                        <br/>
                    </Flex>
                )
            default:
                return <DefaultElement {...props} />;

        }
    }, []);

    const renderLeaf = useCallback(({ attributes, children, leaf }: { attributes: any, children: any, leaf: any }) => {
        const extendedAttributes = {
            ...attributes,
            'data-start': leaf.start,
            'data-end': leaf.end,
            style: {
                backgroundColor: leaf.highlight ? '#FDE047' : 'transparent',
                transition: 'background-color 0.2s'
            }
        };

        if (leaf.isAdvertisement) {
            children = <span style={{ backgroundColor: '#FFFFF0', color: '#B7791F' }}>{children}</span>;
        } else if (leaf.isInaudibleContent) {
            children = <span style={{ backgroundColor: '#FFF5F5', color: '#C53030'}}>{children}</span>;
        }

        if (leaf.bold) {
            children = <strong>{children}</strong>
        }

        if (leaf.italic) {
            children = <em>{children}</em>
        }

        if (leaf.underlined) {
            children = <u>{children}</u>
        }

        return <span {...extendedAttributes}>{children}</span>;
    }, []);

    const handleSetSpeaker = (props: any, name: string) => {
        const pathToCurrentNode = ReactEditor.findPath(editor, props.element);
        const oldSpeakerName = props.element.speaker;
        const isUpdateAllSpeakerInstances = window.confirm(`Would you like to replace all occurrences of ${oldSpeakerName} with ${name}?`);

        if (isUpdateAllSpeakerInstances) {
            const rangeForTheWholeEditor = Editor.range(editor, []);
            Transforms.setNodes(
                editor,
                { type: 'timedText', speaker: name } as Partial<Node>,
                {
                    at: rangeForTheWholeEditor,
                    match: (node: any) => node.type === 'timedText' && node.speaker.toLowerCase() === oldSpeakerName.toLowerCase(),
                }
            );
        } else {
            Transforms.setNodes(editor, {type: 'timedText', speaker: name} as Partial<Node>, { at: pathToCurrentNode })
        }
    }

    const DefaultElement = ({...props}) => {
        return (
            <>
                <p {...props.attributes}>{props.children}</p>
                <br/>
            </>
        );
    };

    const handleChange = (data: any) => {
        //get the position of the cursor and its node
        const { selection } = editor;
        //console.log(selection)
        //let index = selection?.anchor?.path[0] as number;
        //const [node] = Editor.node(editor, selection);
        //console.log(value[index])
        setValue(data);

        const isAstChange = editor.operations.some(
            op => 'set_selection' !== op.type
        )
        if (isAstChange) {
            // Save the value to Local Storage.
            saveLocalTranscript(data);

        }
        //saveLocalTranscript(data);
    };

    const saveLocalTranscript = (data: any) => {
        const content = JSON.stringify(data)
        localStorage.setItem(`${user.userId}-${podcastId}-${episodeId}`, JSON.stringify(content))
    }

    const deleteLocalTranscript = () => {
        localStorage.removeItem(`${user.userId}-${podcastId}-${episodeId}`)
    }

    const handleOnKeyDown = (event: any) => {

        //handle ctrl + z
        if (event.ctrlKey && event.key === 'z') {
            event.preventDefault()
            editor.undo()
        }

        if (event.key === 'Enter') {
            // intercept Enter, and handle timecodes when splitting a paragraph
            event.preventDefault();
            // console.info('For now disabling enter key to split a paragraph, while figuring out the aligment issue');
            // handleSetPauseWhileTyping();
            // TODO: Edge case, hit enters after having typed some other words?
            handleSplitParagraph(editor);
        }
    }

    const getHighlightedTimestamps = (editor: any) => {
        const { selection } = editor;

        if (!selection || Range.isCollapsed(selection)) return;

        const isReversed = Point.isAfter(selection.anchor, selection.focus);

        const textNodes = Array.from(
            Editor.nodes(editor, {
                at: selection,
                match: n => SlateText.isText(n),
            })
        );

        if (!textNodes.length) return;

        const [firstTextNode, firstPath] = textNodes[0];
        const [lastTextNode, lastPath] = textNodes[textNodes.length - 1];

        const selectedText = Editor.string(editor, selection);

        return { startTime: 'NaN', endTime: 'NaN', text: selectedText };
    };

    const findWordForTextNode = (textNode: any, path: any, editor: any, offset: number) => {
        const [parentNode]: any = Editor.parent(editor, path);

        if (!parentNode.children || !parentNode.children[0].words) return null;

        const wordsArray = parentNode.children[0].words;

        // Split the textNode text into individual words
        const textNodeWords = textNode.text.split(/\s+/);

        // Determine which word the offset falls within
        let currentLength = 0;
        let targetWord: any = null;
        for (let word of textNodeWords) {
            currentLength += word.length + 1; // +1 for the space
            if (currentLength >= offset) {
                targetWord = word;
                break;
            }
        }

        if (!targetWord) return null;

        // Find the corresponding word object for the target word
        const wordObj = wordsArray.find((word: any) => word.text === targetWord);

        return wordObj;
    }


    const HoveringToolbar = () => {
        const ref = useRef<HTMLDivElement | null>()
        const editor = useSlate()
        const inFocus = useFocused()
        const [openEditBox, setOpenEditBox] = useState<boolean>(false)

        useEffect(() => {
            try {
                const el = ref.current
                const { selection } = editor
                if (!el) {
                    return
                }

                if (
                    !selection ||
                    !inFocus ||
                    Range.isCollapsed(selection) ||
                    Editor.string(editor, selection) === ''
                ) {
                    el.removeAttribute('style')
                    return
                }

                const domSelection: any = window.getSelection()
                const domRange = domSelection.getRangeAt(0)
                const rect = domRange.getBoundingClientRect();
                el.style.opacity = '1';
                el.style.top = `${rect.bottom + window.scrollY}px`;
                el.style.left = `${rect.left + window.scrollX + (rect.width / 2) - (el.offsetWidth / 2)}px`// <-- Call the function here
            } catch (e) {
                console.log(e)
            }

        } )

        return (
            <ToolbarPortal  ref={ref}>
                <ToolbarMenu
                    ref={ref}
                    onMouseDown={(e: any) => {e.preventDefault()}}
                >
                    <AdvertisementButton
                        icon={<RiPriceTag3Line style={{color: '#B7791F',}}/>}
                        name={'Advertisement'}
                        activeName={'Remove Advertisement'}
                    />
                    <InaudibleContentButton
                        icon={<RiQuestionMark style={{color: '#C53030'}}/>}
                        name={'Inaudible Content'}
                        activeName={'Remove Inaudible Content'}
                    />
                    <FormatButton format="bold" icon={<FaBold />} />
                    <FormatButton format="italic" icon={<FaItalic />} />
                    <FormatButton format="underlined" icon={<FaUnderline />} />
                </ToolbarMenu>
            </ToolbarPortal>
        )
    }

    const toggleFormatMark = (editor: any, format: any, start: any, end: any) => {
        const isActive = isMarkActive(editor, format)

        if (isActive) {
            Editor.removeMark(editor, format)
        } else {
            Editor.addMark(editor, format, true)
        }
    }

    const EditContentButton = ({ format, icon, name, activeName, openEditBox }: { format: any, icon: any, name: string, activeName: string, openEditBox: any }) => {
        const editor = useSlate()
        const isActive = isMarkActive(editor, format)
        let data = getHighlightedTimestamps(editor)
        let action = {
            ...data,
            type: 'inaudibleContent'
        }
        return (
            <ToolbarButton
                reversed
                active={isMarkActive(editor, format)}
                onClick={() => {openEditBox(true)}}
            >
                {icon}
                <Text color="gray.600" fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="16px">
                    {isActive? activeName : name}
                </Text>
            </ToolbarButton>
        )
    }

    const FormatButton = ({ format, icon }: { format: any, icon: any}) => {
        const editor = useSlate()
        let data = getHighlightedTimestamps(editor)
        return (
            <ToolbarButton
                reversed
                active={isMarkActive(editor, format)}
                onClick={() => toggleFormatMark(editor, format, data?.startTime, data?.endTime)}
            >
                <Icon>{icon}</Icon>
            </ToolbarButton>
        )
    }

    const AdvertisementButton = ({ icon, name, activeName }: { icon: any, name: string, activeName: string }) => {
        const editor = useSlate()
        const isActive = isMarkActive(editor, 'isAdvertisement')
        let data = getHighlightedTimestamps(editor)
        let action = {
            ...data,
            type: 'advertisement'
        }
        return (
            <ToolbarButton
                reversed
                active={isActive}
                onClick={
                    () => {
                        toggleMark(editor, data?.startTime, data?.endTime)
                        if (!isActive) {
                            updateActions(action)
                        }                }}
                bg={isActive? "yellow.50" : "white"}
            >
                {icon}
                <Text color={isActive? "yellow.600" : "gray.600"} fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="16px">
                    {isActive? activeName : name}
                </Text>
            </ToolbarButton>
        )
    }

    const InaudibleContentButton = ({ icon, name, activeName }: { icon: any, name: string, activeName: string }) => {
        const editor = useSlate()
        const isActive = isMarkActive(editor, 'isInaudibleContent')
        let data = getHighlightedTimestamps(editor)
        let action = {
            ...data,
            type: 'inaudibleContent'
        }
        return (
            <ToolbarButton
                reversed
                active={isMarkActive(editor, 'isInaudibleContent')}
                onClick={
                    () => {
                        InaudibleMark(editor, data?.startTime, data?.endTime)
                        if (!isActive) {
                            updateActions(action)
                        }
                    }}
            >
                {icon}
                <Text color="gray.600" fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="16px">
                    {isActive? activeName : name}
                </Text>
            </ToolbarButton>
        )
    }

    const toggleMark = (editor: any, start: any, end: any) => {
        const isActive = isMarkActive(editor, 'isAdvertisement')

        if (isActive) {
            Editor.removeMark(editor, 'isAdvertisement')
        } else {
            Editor.addMark(editor, 'isAdvertisement', true)
            Editor.addMark(editor, 'start', start)
            Editor.addMark(editor, 'end', end)
        }
    }

    const InaudibleMark = (editor: any, start: any, end: any) => {
        const isActive = isMarkActive(editor, 'isInaudibleContent')
        if (isActive) {
            Editor.removeMark(editor, 'isInaudibleContent')
        } else {
            Editor.addMark(editor, 'isInaudibleContent', true)
            Editor.addMark(editor, 'start', start)
            Editor.addMark(editor, 'end', end)
        }
    }

    const isMarkActive = (editor: any, format: any) => {
        const marks: any = Editor.marks(editor)
        return marks ? marks[format] === true : false
    }

    const highlightLeafNode = (editor: any, start: number, end: number) => {
    // Find all nodes in the editor
    const nodes = Array.from(
        Editor.nodes(editor, {
            at: [],
            match: n => (n as any).type === 'timedText'
        })
    );

        // Clear previous highlights
        nodes.forEach(([node, path]) => {
            Transforms.setNodes(
                editor,
                { type: 'timedText', highlight: false } as Partial<Node>,
                { at: path }
            );
        });

        // Find and highlight the matching node
        const matchingNode = nodes.find(([node]: any) =>
            node.start <= start && node.end >= end
        );

        if (matchingNode) {
            Transforms.setNodes(
                editor,
                { type: 'timedText', highlight: true } as Partial<Node>,
                { at: matchingNode[1] }
            );
        }
}

    const handleTimeUpdate = (e: any) => {
    const currentTime = e * 1000; // Convert to milliseconds
    highlightLeafNode(editor, currentTime, currentTime);
};

    const handleTimeUpdateOld = (e: any) => {
        const currentTime = e; // Convert to milliseconds

        // Find the word that matches the current playback time
        const val = findWordByTime(Math.round(currentTime), value)
        const wordToHighlight = val?.wordToHighlight;
        const node = val?.node;

        if (wordToHighlight) {
            if (lastHighlightedWordIndex !== null) {
                // Highlight all words between the last highlighted word and the current word
                for (let i = lastHighlightedWordIndex + 1; i <= wordToHighlight.id; i++) {
                    const word = findWordById(i, value);
                    if (word && !word.highlight) {
                        highlightWord(word, node);
                    }
                }
            } else {
                highlightWord(wordToHighlight, node);
            }
            setLastHighlightedWordIndex(wordToHighlight.id);
        }
    };

    /**
     * This function uses binary search to find a word in the transcript based on a given timestamp.
     *
     * @param {number} time - The current timestamp (in milliseconds) from the audio player.
     * @param {Array} wordsArray - The array of words from the transcript.
     *
     * @returns {Object|null} - Returns the word object that matches the given timestamp or null if no word is found.
     */
    const findWordByTimeBinarySearch = (time: any, wordsArray: any) => {
        const BUFFER = 5;  // 5 milliseconds buffer

        let low = 0;
        let high = wordsArray.length - 1;

        while (low <= high) {
            const mid = Math.floor((low + high) / 2);
            const word = wordsArray[mid];

            if (time >= word.start - BUFFER && time <= word.end + BUFFER) {
                return word;
            } else if (time < word.start - BUFFER) {
                high = mid - 1;
            } else {
                low = mid + 1;
            }
        }

        // If we're here, it means the time is in between two words.
        // Return the earlier word if `high` is within bounds.
        if (high >= 0) {
            return wordsArray[high];
        }

        return null;
    };

    const findWordById = (id: number, value: any) => {
        for (let node of value) {
            if (node.children && node.children[0].words) {
                const word = node.children[0].words.find((w: any) => w.id === id);
                if (word) {
                    return word;
                }
            }
        }
        return null;
    };

    const findWordByTime = (time: any, value: any) => {
        for (let node of value) {
            if (node.children && node.children[0].words) {
                const wordObj = findWordByTimeBinarySearch(time, node.children[0].words);
                if (wordObj) {
                    return {wordToHighlight: wordObj, node: node}
                }
            }
        }
        return null;
    };

    const findWordByIteratingThroughSpans = (time: any) => {
        //get all spans with data-start and data-end attributes
        const attributeName = 'data-word-start'; // Replace with your specific attribute name
        const elements = Array.from(document.querySelectorAll(`[${attributeName}]`));
        console.log('elements:', elements.length)
        for (let span of elements) {
            const start = Number(span.getAttribute('data-word-start'));
            const end = Number(span.getAttribute('data-word-end'));
            const id = Number(span.getAttribute('data-word-id'));
            if (start && end && time >= start && time <= end) {
                console.log('Highlighting id:', span.getAttribute('data-word-id'))
                span.setAttribute('style', 'background-color: yellow;')
            }
        }
    }

    const findWordByBinarySearch = (time: any) => {
        const BUFFER = 5;  // 5 milliseconds buffer
        const attributeName = 'data-word-start'; // Replace with your specific attribute name
        const elements = Array.from(document.querySelectorAll(`[${attributeName}]`));
        let currentHighlightedWordId = null;

        let left = 0;
        let right = elements.length - 1;

        while (left <= right) {
            const mid = Math.floor((left + right) / 2);
            const span = elements[mid];
            const start = Number(span.getAttribute('data-word-start'));
            const end = Number(span.getAttribute('data-word-end'));
            const id = Number(span.getAttribute('data-word-id'));

            if (time >= start - BUFFER && time <= end + BUFFER) {
                // Highlight the matching span
                span.setAttribute('style', 'background-color: teal;');
                for (let i = mid - 1; i >= 0; i--) {
                    elements[i].setAttribute('style', 'background-color: transparent;');
                    //remove highlight from previous spans
                }
                return;
            } else if (time < start - BUFFER) {
                right = mid - 1;
            } else {
                left = mid + 1;
            }
        }

        if (right >= 0) {
            return elements[right];
        }
        return null;
    };

    const highlightWord = (wordObj: any, node: any) => {
        console.log("Trying to highlight node:", node);
        const pathToCurrentNode = ReactEditor.findPath(editor, node);

        Transforms.setNodes(
            editor,
            { highlight: true } as Partial<CustomText>,
            {
                at: pathToCurrentNode,
                match: n => SlateText.isText(n) && n.text === wordObj.text,
            }
        );
    };


    const updateActions = (newAction: any) => {
        let current = actions
        current.push(newAction)
        setActions(current)
    }

    const removeWordsParamForChildren = (transcript: any) => {
        let newTranscript = transcript.map((paragraph: any) => {
            let newParagraph = { ...paragraph };
            newParagraph.children = paragraph.children.map((children: any) => {
                let newChildren = { ...children };
                delete newChildren.words;
                return newChildren;
            });
            return newParagraph;
        });
        return newTranscript;
    }

     const AutomaticallyScrollToBottom = () => {
        const elementRef = useRef<HTMLDivElement>(null!);

        useEffect(() =>
            elementRef?.current?.scrollIntoView({ behavior: 'smooth' }),
        );
        return <div ref={transcriptRef} />;
    };

    let image_url = "https://megaphone.imgix.net/podcasts/042e6144-725e-11ec-a75d-c38f702aecad/image/Huberman-Lab-Podcast-Thumbnail-3000x3000.png?ixlib=rails-4.3.1&max-w=3000&max-h=3000&fit=crop&auto=format,compress"

    const highlightParagraphByTime = (editor: ReactEditor, currentTime: number) => {
        // Find all paragraph nodes in the editor
        const paragraphNodes = Array.from(
            Editor.nodes(editor, {
                at: [],
                match: (node: any) => node.type === 'timedText' && node.start !== undefined
            })
        );

        // First, clear any existing highlights
        paragraphNodes.forEach(([node, path]) => {
            Transforms.setNodes(
                editor,
                { highlight: false } as Partial<Node>,
                { at: path }
            );
        });

        // Find the paragraph that matches the current time
        const matchingParagraph = paragraphNodes.find(([node]: any) =>
            node.start <= currentTime && node.end >= currentTime
        );

        // If a matching paragraph is found, highlight it
        if (matchingParagraph) {
            const [node, path] = matchingParagraph;
            Transforms.setNodes(
                editor,
                { highlight: true } as Partial<Node>,
                { at: path }
            );
        }
    };

// Usage in your component
    const handleTimeUpdateNew = (e: any) => {
        const currentTime = e * 1000; // Convert to milliseconds
        highlightParagraphByTime(editor, currentTime);
    };
    // @ts-ignore
    // @ts-ignore
    return (
        <Flex w={'full'} direction={'column'} gap={'36px'} mt={'8px'}>
            {!transcriptLoading && <Flex w={'full'} p="8px" h={'full'} direction="row" alignItems="center" justifyContent="space-between" bgColor={'white'} borderRadius="12px" border="1px" borderColor="gray.300" py={'3px'}>
                <Flex w={'full'} p="16px" flexDirection="row" justifyContent={'space-between'} alignItems="center" gap="12px">
                    <Flex alignItems={'center'} gap="16px" alignSelf={'stretch'}>
                        <ChevronLeftIcon  boxSize={'36px'} onClick={() => {
                            window.history.back()
                        }}/>
                        <Image fallbackSrc={image_url} src={`${task?.episode.image_url}`} boxSize={'64px'} borderRadius="12px" />
                        <Flex direction={'column'} alignItems={'flex-start'} gap={'8px'} flex={'1 0 0'}>
                            <Text color="gray.800" textAlign="center" isTruncated fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                                {task?.episode?.title}
                            </Text>
                            <Flex direction={'row'} alignItems={'center'} gap={'8px'} alignSelf={'stretch'}>
                                <Text color="gray.500" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                                    {secondsToHHMMSS(task?.episode?.duration as any)}
                                </Text>
                            </Flex>
                            <Text fontWeight={500} fontSize={'12px'} fontFamily={'sans-serif'}  color={'gray.600'}>
                                {`$${Math.round(task?.episode.duration as any/60) * 1.5} USD`}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex direction={'column'} gap={'12px'} justifyContent={'flex-end'}>
                        <Button variant={'solid'} size= "md" colorScheme="teal" onClick={onOpen}>
                            Submit
                        </Button>
                        <Button
                            onClick={() => {
                                if (!transcriptSaving) {
                                    setTranscriptSaving(true)
                                    let finalValue = removeWordsParamForChildren(value)

                                    transcriptSaveReq.setPayloadData({
                                        transcript: finalValue,
                                        podcastId: podcastId,
                                        episodeId: episodeId,
                                        sessionId: sessionId
                                    })
                                } else {
                                    toast({
                                        title: "Transcript is already saving",
                                        description: "Please wait until the transcript is saved",
                                        status: "warning",
                                        duration: 3000,
                                        isClosable: true,
                                    })
                                }
                            }}
                            variant={'outline'}
                            size= "md"
                            colorScheme="teal">
                            Save
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
            }
            <Flex w={'full'} direction="row" justifyContent={'space-between'} gap={'32px'} p={'0px'}>
                <Flex w={'50%'} p={'20px'} bgColor={'white'} boxShadow={'md'} borderRadius={12}>
                    <Flex ref={transcriptRef} w={'full'} h={'full'} style={{ maxHeight: '70vh', overflow: 'scroll' }}>
                        {transcriptLoading?
                            <SkeletonText w={'full'} h={'full'} mt='4' noOfLines={60} spacing='4' skeletonHeight='2' />
                            :
                            <Flex direction={'column'} gap={'10px'} align={'center'} justify={'center'} w={'100%'} h={'100%'}>
                                <Slate
                                    editor={editor}
                                    onChange={handleChange}
                                    value={value}>
                                    <HoveringToolbar />
                                    <Editable
                                        renderElement={renderElement}
                                        renderLeaf={renderLeaf}
                                        onKeyDown={handleOnKeyDown}
                                        autoFocus
                                        style={{ height: '100%', overflowY: 'scroll' }}
                                    />
                                </Slate>
                            </Flex>
                        }
                    </Flex>
                </Flex>
                <Flex direction="column" gap={'12px'} w={'50%'} h={transcriptLoading? '50%' : 'fit-content'} p={'20px'} bgColor={'white'} boxShadow={'md'} borderRadius={12}>
                    {transcriptLoading && <Skeleton w={'full'} h={'full'} />}
                    {!transcriptLoading && <>
                        <ReactPlayer
                            width={'100%'}
                            controls={true}
                            url= {task?.youtube_url}
                            style={{borderRadius: '12px'}}
                            onProgress={(progress) => {
                                handleTimeUpdateNew(progress.playedSeconds);
                            }}
                            onSeek={(time) => {
                                setCurrentTimestamp(time);
                            }}
                        />

                    </>
                    }
                </Flex>
            </Flex>
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader>Submit task?</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody w={'full'} justifyContent={'center'} alignItems={'center'}>
                        {submitting?
                            <Flex w={'full'} justifyContent={'center'} alignItems={'center'}>
                                <Spinner alignSelf={'center'} color={'teal'}/>
                            </Flex>
                            :
                            <Text>
                                Are you sure you want to submit this task? You will not be able to edit it after submission.
                            </Text>
                        }
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            No
                        </Button>
                        <Button colorScheme='red' ml={3} onClick={() => {
                            setSubmitting(true)
                            transcriptSubmitReq.setPayloadData({transcript: value, labeler: user.user})
                        }}>
                            Yes
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </Flex>
    );
}
